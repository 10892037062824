<template>
  <v-card>
    <v-card-title>
      Demandes
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        outlined
        dense
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="equivalences"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      class="elevation-1"
      locale="fr-CH"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [10, 30, 50],
        itemsPerPageText: 'Élements par page',
      }"
    >
      <template v-slot:body="{ items }">
        <tbody class="text-left">
          <tr
            v-for="item in items"
            :key="item._id"
            @click="$router.push(`/equivalences/${item._id}`)"
          >
            <td>{{ item.requester.lastname }}</td>
            <td>{{ item.requester.firstname }}</td>
            <td>{{ item.modules.map((m) => m.basemodule.name).join(", ") }}</td>
            <td>
              {{
                item.modules.filter(
                  (module) =>
                    module.managerStatus && module.managerStatus !== "pending"
                ).length
              }}/{{ item.modules.length }}
            </td>
            <td>{{ formatDate(item.createdAt) }}</td>
            <td>{{ formatDate(item.updatedAt) }}</td>
            <td>
              <v-btn
                text
                @click.stop="downloadBundle(item._id)"
                :disabled="downloading && downloading !== item._id"
                :loading="downloading && downloading === item._id"
                small
                plain
                :ripple="false"
                >Dossier</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
      >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  props: ["formatDate", "downloadBundle", "downloading"],
  data: () => ({
    equivalences: [],
    options: {},
    total: 0,
    loading: false,
    search: "",
    headers: [
      {
        text: "Nom",
        value: "orderId",
        sortable: false,
      },
      {
        text: "Prénom",
        value: "value",
        sortable: false,
      },
      {
        text: "Modules",
        value: "modules",
        sortable: false,
      },
      {
        text: "Validations RF",
        sortable: false,
      },
      {
        text: "Date de création",
        value: "createdAt",
        sortable: true,
      },
      {
        text: "Date de mise à jour",
        value: "updatedAt",
        sortable: true,
      },
      {
        text: "Téléchargements",
        value: "downloads",
        sortable: false,
      },
    ],
  }),
  watch: {
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.equivalences = data.items;
        this.total = data.total;
      },
      deep: true,
    },
    "$route.query.status": {
      async handler() {
        this.options = { page: 1, itemsPerPage: 10 };
      },
      deep: true,
    },
    dialog(after) {
      if (!after) {
        this.initialData = undefined;
      }
    },
    search: debounce(function (newValue) {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            search: newValue || undefined,
          },
        })
        .catch(() => {}); // Catch and ignore duplicated navigation errors}) {
      // Update the URL query string when the model changes
    }, 500),
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/manager/v2/equivalences?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&status=${this.$route.query.status}&search=${this.search}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
  },
};
</script>