var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.equivalence)?_c('v-card',{attrs:{"outlined":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","small":"","plain":"","ripple":false},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_vm._v("retour")],1)],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","loading":_vm.downloading && _vm.downloading === _vm.equivalence._id,"small":"","plain":"","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.downloadBundle(_vm.equivalence._id)}}},[_vm._v("Télécharger le dossier "),_c('v-icon',{staticClass:"mb-1",attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-download-outline ")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-title',{staticClass:"pa-10 font-weight-light"},[_vm._v("Demande d'équivalence")]),_c('v-card-text',{staticClass:"px-10 font-weight-light"},[(_vm.equivalence)?_c('equivalence-form',{attrs:{"equivalence":_vm.equivalence,"formatDate":_vm.formatDate,"readOnly":_vm.readOnly}}):_vm._e(),(
        ['submitted', 'invalid', 'closed'].indexOf(_vm.equivalence.status) ===
          -1 &&
        _vm.equivalence.modules.map(function (m) { return m.managerStatus; }).indexOf('pending') ===
          -1 &&
        _vm.equivalence.decisionDocuments &&
        _vm.equivalence.decisionDocuments.length
      )?_c('div',[_c('v-divider'),_c('div',{staticClass:"my-3"},[_vm._v("Décisions générées")]),_vm._l((_vm.equivalence.decisionDocuments),function(document){return _c('div',{key:document._id},[_c('div',[_vm._v("Généré le "+_vm._s(_vm.formatDate(document.generatedAt)))]),_c('a',{on:{"click":function($event){return _vm.downloadDocument(document.name)}}},[_vm._v("decision.pdf")])])})],2):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","plain":"","ripple":false,"disabled":_vm.equivalence.status === 'complete',"loading":_vm.loading.submit},on:{"click":_vm.submit}},[_vm._v(" Mettre à jour la demande ")])],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}],null,false,474744036),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }