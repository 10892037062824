import Vue from "vue";
import VueRouter from "vue-router";
import Equivalences from "@/views/Equivalences.vue";
import Details from "@/views/Details.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { path: "/equivalences", query: { status: "pending" } },
  },
  {
    path: "/equivalences",
    name: "Equivalences",
    component: Equivalences,
  },
  {
    path: "/equivalences/:_id",
    name: "Request details",
    component: Details,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
