<template>
  <v-form>
    <div class="mb-5">Statut demande</div>
    <v-select
      outlined
      dense
      label="Statut"
      :items="statuses"
      v-model="equivalence.status"
      disabled
    ></v-select>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Demandeur</div>
    <v-autocomplete
      v-if="!editMode"
      v-model="equivalence.requester"
      :items="students"
      :search-input.sync="searchStudents"
      label="Demandeur *"
      outlined
      dense
      @input="clearSearchStudents"
      no-data-text="Tapez le nom d'une personne..."
    >
    </v-autocomplete>
    <v-text-field
      v-else
      disabled
      outlined
      dense
      label="Demandeur"
      :value="`${equivalence.requester.firstname} ${equivalence.requester.lastname} (${equivalence.requester.email})`"
    ></v-text-field>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Modules sélectionnés</div>
    <v-autocomplete
      label="Modules *"
      :items="modules"
      item-text="name"
      item-value="_id"
      v-model="selectedModules"
      outlined
      dense
      multiple
      disabled
    ></v-autocomplete>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Détails des modules</div>
    <div v-for="module in equivalence.modules" :key="module._id">
      <v-container class="pa-0">
        <v-row>
          <v-col clos="12" sm="6">
            <v-autocomplete
              v-model="module.studyType"
              :label="`Type d'étude pour ${
                module.name || module.basemodule.name
              } *`"
              :items="studyTypes"
              outlined
              dense
              disabled
            ></v-autocomplete>
            <v-textarea
              outlined
              dense
              :label="`Explication pour ${
                module.name || module.basemodule.name
              } *`"
              v-model="module.description"
              disabled
              auto-grow
            ></v-textarea>
          </v-col>
          <v-col clos="12" sm="6">
            <v-select
              outlined
              dense
              label="Décision"
              v-if="module.managerStatus"
              :items="managerStatuses"
              v-model="module.managerStatus"
              :hint="
                module.updatedByManager
                  ? `${module.updatedByManager.firstname} ${
                      module.updatedByManager.lastname
                    } (${formatDate(module.updatedAtManager)})`
                  : undefined
              "
              persistent-hint
              @change="updateUnitiesAndComments(module)"
              :disabled="equivalence.status === 'complete'"
            ></v-select>
            <v-textarea
              outlined
              dense
              label="Unités concernées"
              v-if="
                [
                  'accepted-partial',
                  'exemption-accepted-partial',
                  'other',
                ].indexOf(module.managerStatus) > -1
              "
              v-model="module.unities"
              :disabled="equivalence.status === 'complete'"
              auto-grow
            ></v-textarea>
            <v-textarea
              outlined
              dense
              label="Remarques"
              v-model="module.comments"
              :disabled="equivalence.status === 'complete'"
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider class="mb-6"></v-divider>
    <v-checkbox
      label="Traitement terminé"
      v-model="equivalence.over"
      :disabled="readOnly"
    ></v-checkbox>
  </v-form>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  props: ["equivalence", "formatDate", "readOnly"],
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/manager/v2/basemodules`,
    });
    this.modules = data.map((m) => {
      m.name = m.deleted ? `${m.name} (module supprimé)` : m.name;
      return m;
    });
    this.editMode =
      this.equivalence.requester && !!this.equivalence.requester._id;
    if (this.editMode) {
      this.selectedModules = this.equivalence.modules.map(
        (m) => m.basemodule._id
      );
    }
  },
  data: () => ({
    modules: [],
    selectedModules: [],
    studyTypes: [
      "HES-SO",
      "EPFL",
      "Université",
      "Autre HES",
      "Autre école suisee (ET, ES, ...)",
      "École étrangère",
      "Autre situation",
    ],
    statuses: [
      { text: "À traiter", value: "submitted" },
      { text: "Valide", value: "valid" },
      { text: "Invalide", value: "invalid" },
      { text: "Terminée", value: "complete" },
    ],
    managerStatuses: [
      { text: "En attente", value: "pending" },
      { text: "Équivalence accordée sur le module complet", value: "accepted" },
      {
        text: "Équivalence partielle sur certaines unités",
        value: "accepted-partial",
      },
      {
        text: "Dispense accordée sur le module complet",
        value: "exemption-accepted",
      },
      {
        text: "Dispense partielle sur certaines unités",
        value: "exemption-accepted-partial",
      },
      { text: "Autre situation - panachage", value: "other" },
      { text: "Aucune équivalence accordée", value: "rejected" },
    ],
    searchStudents: null,
    students: [],
    uploading: {},
    editMode: undefined,
  }),
  methods: {
    updateModules(modules) {
      this.equivalence.modules = this.equivalence.modules.filter(
        (m) => modules.indexOf(m.name) > -1
      );
      modules.map((name) => {
        if (!this.equivalence.modules.find((m) => m.name === name)) {
          this.equivalence.modules.push({ name });
        }
      });
    },
    async queryPicker(v) {
      this.loading = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_PEOPLEPICKER_URI}/search?s=${v}`,
        headers: {
          "x-api-key": process.env.VUE_APP_PEOPLEPICKER_KEY,
        },
      });
      this.students = contacts.map((c) => ({
        text: `${c.firstname} ${c.lastname}`,
        value: c,
      }));
      this.loading = false;
    },
    clearSearchStudents() {
      this.searchStudents = null;
    },
    updateUnitiesAndComments(module) {
      module.unities = "";
    },
  },
  computed: {
    langModuleSelected() {
      return (
        this.modules
          .filter((m) => this.selectedModules.indexOf(m.name) > -1)
          .map((m) => m.lang)
          .indexOf(true) > -1
      );
    },
  },
  watch: {
    "equivalence.requester": function (value) {
      this.editMode = !!value._id;
    },
    searchStudents: _.debounce(function (val) {
      val && this.queryPicker(val, "students");
    }, 300),
    selectedModules(value) {
      this.equivalence.modules = this.modules
        .filter((module) => value.indexOf(module._id) > -1)
        .map((module) => {
          return (
            this.equivalence.modules.find(
              (m) => m.basemodule && m.basemodule._id === module._id
            ) || module
          );
        });
    },
  },
};
</script>