var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Demandes "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.equivalences,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"locale":"fr-CH","search":_vm.search,"footer-props":{
      itemsPerPageOptions: [10, 30, 50],
      itemsPerPageText: 'Élements par page',
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item._id,on:{"click":function($event){return _vm.$router.push(("/equivalences/" + (item._id)))}}},[_c('td',[_vm._v(_vm._s(item.requester.lastname))]),_c('td',[_vm._v(_vm._s(item.requester.firstname))]),_c('td',[_vm._v(_vm._s(item.modules.map(function (m) { return m.basemodule.name; }).join(", ")))]),_c('td',[_vm._v(" "+_vm._s(item.modules.filter( function (module) { return module.managerStatus && module.managerStatus !== "pending"; } ).length)+"/"+_vm._s(item.modules.length)+" ")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))]),_c('td',[_c('v-btn',{attrs:{"text":"","disabled":_vm.downloading && _vm.downloading !== item._id,"loading":_vm.downloading && _vm.downloading === item._id,"small":"","plain":"","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.downloadBundle(item._id)}}},[_vm._v("Dossier")])],1)])}),0)]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }