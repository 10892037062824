<template>
  <v-card outlined v-if="equivalence">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
            text
            small
            plain
            class="mt-2"
            @click="$router.go(-1)"
            :ripple="false"
            ><v-icon small class="mr-2">mdi-arrow-left</v-icon>retour</v-btn
          ></v-col
        >
        <v-col class="text-right">
          <v-btn
            text
            @click.stop="downloadBundle(equivalence._id)"
            :loading="downloading && downloading === equivalence._id"
            small
            plain
            :ripple="false"
            class="mt-1"
            >Télécharger le dossier
            <v-icon right dark class="mb-1">
              mdi-cloud-download-outline
            </v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-title class="pa-10 font-weight-light"
      >Demande d'équivalence</v-card-title
    >
    <v-card-text class="px-10 font-weight-light">
      <equivalence-form
        :equivalence="equivalence"
        v-if="equivalence"
        :formatDate="formatDate"
        :readOnly="readOnly"
      />
      <div
        v-if="
          ['submitted', 'invalid', 'closed'].indexOf(equivalence.status) ===
            -1 &&
          equivalence.modules.map((m) => m.managerStatus).indexOf('pending') ===
            -1 &&
          equivalence.decisionDocuments &&
          equivalence.decisionDocuments.length
        "
      >
        <v-divider></v-divider>
        <div class="my-3">Décisions générées</div>
        <div
          v-for="document in equivalence.decisionDocuments"
          :key="document._id"
        >
          <div>Généré le {{ formatDate(document.generatedAt) }}</div>
          <a @click="downloadDocument(document.name)">decision.pdf</a>
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        plain
        @click="submit"
        :ripple="false"
        :disabled="equivalence.status === 'complete'"
        :loading="loading.submit"
      >
        Mettre à jour la demande
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import EquivalenceForm from "@/components/EquivalenceForm.vue";
import fileDownload from "js-file-download";
export default {
  name: "Create",
  components: { EquivalenceForm },
  props: ["downloadBundle", "downloading", "formatDate", "readOnly"],
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/manager/v2/equivalences/${this.$route.params._id}`,
    });
    this.equivalence = data;
  },
  data: () => ({
    loading: { submit: false },
    equivalence: undefined,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    async downloadDocument(name) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${name}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "decision.pdf");
    },
    async submit() {
      try {
        this.loading.submit = true;
        const { data } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/manager/equivalences/${this.equivalence._id}`,
          data: {
            ...this.equivalence,
          },
        });
        this.loading.submit = false;
        this.dialog = false;
        this.equivalence = data;
        this.snackbar = {
          visible: true,
          color: "success",
          text: "La demande a été mise à jour.",
        };
      } catch (error) {
        console.log(error);
        this.loading.submit = false;
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur lors de la soumission.",
        };
      }
    },
  },
  computed: {
    validForm() {
      return (
        this.equivalence.modules.length > 0 &&
        this.equivalence.modules.map((m) => "studyType" in m).indexOf(false) ===
          -1 &&
        [...new Set(this.equivalence.modules.map((m) => m.studyType))].filter(
          (m) => m
        ).length === this.equivalence.documents.length
      );
    },
  },
};
</script>s